@import "~@ng-select/ng-select/themes/default.theme.css";
body {
  background-color: rgba(235, 239, 242, 1) !important;
}
.t-sort {
  font-size: 13px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
}

[tuiWrapper][data-appearance="primary"] {
  background: #59f89c !important;
}

[tuiWrapper][data-appearance="primary-button"] {
  background: #59f89c !important;
  color: #1f2326 !important;
}
[tuiWrapper][data-appearance="white-button"] {
  background: #ffff !important;
  color: #1f2326 !important;
}

[tuiWrapper][data-appearance="secondary"],
[tuiWrapper][data-appearance="flat"],
[tuiWrapper][data-appearance="destructive"] {
  color: #180c2c;
}

[tuiWrapper][data-appearance="secondary"],
[tuiWrapper][data-appearance="flat"],
[tuiWrapper][data-appearance="destructive"]:hover {
  background-color: transparent !important;
  color: inherit;
}

[tuiAppearance][data-appearance="link"] {
  color: #1f2326;
}

.badge-color-external {
  background: #e1e6ec;
}

.t-dots {
  color: black !important;
  font-weight: 600 !important;
}

.t-content {
  /*  font-weight: 600 !important; */
}

[data-status="success"][_nghost-ng-c3553744663] {
  min-width: 309px !important;
  min-height: 92px !important;
}

.t-mark {
  color: black !important;
}
.custom-container {
  padding: 30px;
}
table[tuiTable] [tuiTitle] {
  white-space: normal !important;
}

.pac-container {
  position: absolute;
  z-index: 1060;
}

input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="time"] {
  position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.custom-clustericon {
  background: var(--cluster-color) !important;
  color: #fff !important;
  border-radius: 100% !important;
  font-weight: bold !important;
  font-size: 15px !important;
  display: flex !important;
  align-items: center !important;
}

.custom-clustericon::before,
.custom-clustericon::after {
  content: "" !important;
  display: block !important;
  position: absolute !important;
  width: 125% !important;
  height: 125% !important;

  transform: translate(-50%, -50%) !important;
  top: 50% !important;
  left: 50% !important;
  background: var(--cluster-color) !important;
  opacity: 0.2 !important;
  border-radius: 100% !important;
}

.custom-clustericon::after {
  width: 150% !important;
  height: 150% !important;
}

.custom-clustericon::before {
  padding: 7px !important;
}

.custom-clustericon::after {
  padding: 14px !important;
}

.custom-clustericon-1 {
  --cluster-color: #00a2d3 !important;
}

.custom-clustericon-2 {
  --cluster-color: #ff9b00 !important;
}

.custom-clustericon-3 {
  --cluster-color: #ff6969 !important;
}

.ng-select .ng-select-container {
  display: inline-flex;

  min-width: 82px !important;
  box-shadow: 0px 2px 3px 0px #0000001a !important;

  border-radius: 12px !important;
  font-size: 13px !important;
  overflow: hidden;
  white-space: nowrap;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  flex-wrap: nowrap !important;
}

.select_languaje {
  height: 44px;
  background: #fff;
  border-radius: 12px;
  border: solid 1px #f2f2f2;
  padding: 0px 6px 0px 15px;
  margin-top: 5px;
  box-shadow: 0px 0px 3px 0px #0000001a;
  font-size: 13px;
}

.language-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.flag-icon {
  width: 20px;
  height: 15px;
  margin-right: 8px;
  vertical-align: middle;
}

.dropdown-menu {
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: white;
  border: 1px solid #ddd;
  width: 100%;
  max-width: 200px;
}

.dropdown-menu li {
  padding: 10px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}
